<template>
	<div @click="itemClick" class="tab">
		<div v-if="isActive"><slot name="on"></slot></div>
		<div v-else><slot name="off"></slot></div>
		<slot name="text"></slot>
	</div>
</template>

<script>

	export default {
		name: "TabItem",
		props: {
			path: String
		},
		computed: {
			isActive() {return this.$route.path.indexOf(this.path) !== -1}
		},
		methods: {
			itemClick() {
				this.$router.replace(this.path)
			}
		}
	}
</script>

<style scoped>
	.tab {
		flex: 1;
		text-align: center;
	}
	img {
		margin-top: .2rem;
		width: 1.3rem;
		height: 1.3rem;
	}
	p {
		height: 1.2rem;
		color: #666;
		font-size: .8rem;
	}
</style>
