<template>
<!--	<div id="app">-->
<!--		<div id="nav">-->
<!--			<router-link to="/">Home</router-link> |-->
<!--			<router-link to="/about">About</router-link>-->
<!--		</div>-->
<!--		<router-view/>-->
<!--	</div>-->
	<div>
		<router-view/>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from "@/components/content/Footer";

	export default {
		name: "APP",
		components: {
			Footer
		}
	}
</script>

<style lang="less">
    @import "assets/css/base";
//#app {
//  font-family: Avenir, Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//}
//
//#nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}
</style>
