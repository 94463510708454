<template>
	<footer>
		<slot></slot>
	</footer>
</template>

<script>
	export default {
		name: "TabBar"
	}
</script>

<style scoped lang="less">
	footer {
		display: flex;
		position: fixed;
		bottom: 0;
		width: 20rem;
		height: 2.7rem;
		background-color: #f4f4f4;
	}
</style>
