<template>
	<tab-bar>
<!--		<tab-item :key="i" v-for="i in tabBar">{{ i }}</tab-item>-->
		<tab-item path="/home">
			<img slot="on" src="@/assets/img/home-on.png" alt="123">
			<img slot="off" src="@/assets/img/home.png" alt="">
			<p slot="text">主页</p>
		</tab-item>
		<tab-item path="/fight">
			<img slot="on" src="@/assets/img/fight-on.png" alt="123">
			<img slot="off" src="@/assets/img/fight.png" alt="">
			<p slot="text">战斗</p>
		</tab-item>
		<tab-item path="/user">
			<img slot="on" src="@/assets/img/user-on.png" alt="123">
			<img slot="off" src="@/assets/img/user.png" alt="">
			<p slot="text">我的</p>
		</tab-item>
	</tab-bar>
</template>

<script>
	import TabBar from "@/components/common/tabbar/TabBar";
	import TabItem from "@/components/common/tabbar/TabItem";
	export default {
		name: "Footer",
		components: {
			TabBar,
			TabItem
		}
	}
</script>

<style scoped>

</style>
