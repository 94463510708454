import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import("@/views/Home")

const Fight = () => import("@/views/Fight")

const User = () => import("@/views/User")
const UserInfo = () => import("@/views/UserInfo")
const Login = () => import("@/views/Login")
const Register = () => import("@/views/Register")

Vue.use(VueRouter)


const routes = [
	{
		path: '/',
		redirect: '/home'
    // component: Home
	},
	{
		path: '/home',
		// name: 'About',
		component: Home
	},
	{
		path: "/fight",
		component: Fight
	},
	{
		path: "/user",
		component: User,
		children: [
			{
				path: "",
				component: UserInfo
			},
			{
				path: "login",
				component: Login
			},
			{
				path: "register",
				component: Register
			}
		]
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
